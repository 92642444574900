import { PubSub } from 'pubsub-js';
import { Button, Modal, Space } from 'antd';
let websocket, lockReconnect = false;
let createWebSocket = (url) => {
    websocket = new WebSocket(url);
    websocket.onopen = function () {
        console.log('连接正常打开中....')
        localStorage.setItem('chronicIsSocket', url)
        heartCheck.reset().start();

    }
    websocket.onerror = function () {
        reconnect(url);
    };
    websocket.onclose = function (e) {

        console.log('websocket 断开: ' + e.code + ' ' + e.reason + ' ' + e.wasClean)
        heartCheck.reset();
        if (e.code !== 1000) {//1000为服务器主动关闭链接
            lockReconnect = false;
            // reconnect(url);
        }

        Modal.destroyAll();
        // Modal.error({
        //     title: "网络错误!",
        //     content: (<>网络连接出现错误，请刷新页面！</>),
        //     onOk: () => {
        //         window.location.reload();
        //     }
        // });

    }
    websocket.onmessage = function (event) {
        lockReconnect = true;
        //event 为服务端传输的消息，在这里可以处理
        let data = JSON.parse(event.data);//把获取到的消息处理成字典，方便后期使用
        PubSub.publish('message', data); //发布接收到的消息 'message' 为发布消息的名称，data 为发布的消息
    }
}
let reconnect = (url, num = 0) => {
    if (lockReconnect) return;
    //没连接上会一直重连，设置延迟避免请求过多
    if (num > 3) {
        setTimeout(function () {
            num++;
            createWebSocket(url, num);
            lockReconnect = false;
        }, 5000);
    }

}
let heartCheck = {
    timeout: 30000, //30秒
    timeoutObj: null,
    reset: function () {
        clearInterval(this.timeoutObj);
        return this;
    },
    start: function () {
        this.timeoutObj = setInterval(function () {
            //这里发送一个心跳，后端收到后，返回一个心跳消息，
            //onmessage拿到返回的心跳就说明连接正常
            // console.log(websocket.readyState)
            if (websocket.readyState != 1) {
                // clearInterval(this.timeoutObj);
                heartCheck.reset().start();
            }
            websocket.send("{act:'heart',token:" + localStorage.getItem("token") + ",client:'admin'}");
        }, this.timeout)
    }
}
//关闭连接
let closeWebSocket = () => {
    heartCheck.reset();
    websocket && websocket.close();
}
// 发送消息
let sendWebSocket = (data) => {
    websocket && websocket.send(data)
}
export {
    websocket,
    createWebSocket,
    closeWebSocket,
    sendWebSocket
};

