import React from 'react';
import { Layout, Menu, Button, Dropdown, message, Tabs, Form, Switch as AntSwitch } from 'antd';
import { createWebSocket, closeWebSocket } from '../class/websocket';
import { PubSub } from 'pubsub-js';
import CustomModal from '../common/Modal';
import AsyncComponent from './AsyncComponent.jsx';

// import RoleList from './Basic/RoleList';  //角色列表
// import AdminList from './Basic/AdminList';  //管理员列表
// import OperationLog from './Basic/OperationLog';  //操作日志
// import MenuSet from './Set/MenuSet';  //菜单设置
// import UploadSet from './Set/UploadSet';  //上传设置
// import BasicInfo from './Set/BasicInfo';  //基本信息设置配置
// import SetColor from './SetColor';  //主题
// import EditPwd from './EditPwd';  //修改密码
// import UserInfo from './UserInfo';  //个人信息

// import BannerList from './Set/BannerList';  //轮播图管理
// import UserReport from './Datas/UserReport';  //用户报表
// import RevenueReport from './Datas/RevenueReport';  //营收报表
// import SelfList from './Tuijian/SelfList';  //自营商品
// import OfflineBusiness from './Tuijian/OfflineBusiness';  //线下商家
// import RiderBusiness from './Tuijian/RiderBusiness';  //外卖商家
// import UserList from './User/UserList';  //用户列表
// import UserIntegral from './User/UserIntegral';  //用户积分明细
// import SelfOrder from './Order/SelfOrder';  //自营订单
// import PurchOrder from './Order/PurchaseOrder';  //采购订单
// import RiderOrder from './Order/RiderOrder';  //外卖订单
// import BusinessType from './Business/BusinessType';  //商家行业类型配置
// import ApplyBusiness from './Business/ApplyBusiness';  //商家申请
// import BusinessList from './Business/BusinessList';  //商家列表
// import BusinessYue from './Business/BusinessYue';  //商家余额明细
// import BusinessTix from './Business/BusinessTix';  //商家提现申请
// import SupplierList from './Supplier/SupplierList';  //供应商列表
// import SupplierBill from './Supplier/SupplierBill';  //供应商账单
// import GoodsType from './Goods/GoodsType';  //商品分类管理
// import GoodsList from './Goods/GoodsList';  //采购商品列表
// import Shopper from './Shopper/Shopper';  //配送员列表
// import DeliveryRecord from './Shopper/DeliveryRecord';  //配送记录
// import Finance from './Finance';  //财务管理
// import Comment from './Comment';  //评论列表
// import BasicInfoAdmin from './Basic/BasicInfo';
// import SelfGoodsList from './Goods/SelfGoodsList';  //自营商品列表
// import RiderGoodsList from './Goods/RiderGoodsList';  //外卖商品列表
// import SetFee from './Set/SetFee';  //配送费设置
// import LocalOrder from './Order/LocalOrder';  //本地汇订单
// import NewUserList from './NewUser/NewUserList';  //拉新榜单
// import SignList from './Sign/SignList';  // 签到历史
// import SignRewardList from './Sign/SignRewardList';  // 奖励历史
// import CouponList from './Coupon/CouponList';  //优惠券列表
// import BankSet from './Set/BankSet';  //开户行配置
// import SignSet from './Sign/SignSet';  // 签到配置
// import WifiPrint from './Business/WifiPrint';
// import XqList from './xq/XqList';
// import Manifest from './Order/Manifest';
// import MerchantCoinLog from './Business/MerchantCoinLog';

const RoleList = AsyncComponent(() => import('./Basic/RoleList'));  //角色列表
const AdminList = AsyncComponent(() => import('./Basic/AdminList'));  //管理员列表
const OperationLog = AsyncComponent(() => import('./Basic/OperationLog'));  //操作日志
const BasicInfoAdmin = AsyncComponent(() => import('./Basic/BasicInfo'));  //平台信息配置
const MenuSet = AsyncComponent(() => import('./Set/MenuSet'));  //菜单设置
const UploadSet = AsyncComponent(() => import('./Set/UploadSet'));  //上传设置
const BasicInfo = AsyncComponent(() => import('./Set/BasicInfo'));  //基本信息设置配置
const SetFee = AsyncComponent(() => import('./Set/SetFee'));  //配送费设置
const BankSet = AsyncComponent(() => import('./Set/BankSet'));  //开户行配置
const BannerList = AsyncComponent(() => import('./Set/BannerList'));  //轮播图设置
const SetColor = AsyncComponent(() => import('./SetColor'));  //主题
const EditPwd = AsyncComponent(() => import('./EditPwd'));  //修改密码
const UserInfo = AsyncComponent(() => import('./UserInfo'));  //个人信息
const UserReport = AsyncComponent(() => import('./Datas/UserReport'));  //用户报表
const RevenueReport = AsyncComponent(() => import('./Datas/RevenueReport'));  //营收报表
const SelfList = AsyncComponent(() => import('./Tuijian/SelfList'));  //自营商品
const OfflineBusiness = AsyncComponent(() => import('./Tuijian/OfflineBusiness'));  //线下商家
const RiderBusiness = AsyncComponent(() => import('./Tuijian/RiderBusiness'));  //外卖商品
const UserList = AsyncComponent(() => import('./User/UserList'));  //用户列表
const UserIntegral = AsyncComponent(() => import('./User/UserIntegral'));  //用户积分明细
const SelfOrder = AsyncComponent(() => import('./Order/SelfOrder'));  //自营订单
const PurchOrder = AsyncComponent(() => import('./Order/PurchaseOrder'));  //采购订单
const RiderOrder = AsyncComponent(() => import('./Order/RiderOrder'));  //外卖订单
const LocalOrder = AsyncComponent(() => import('./Order/LocalOrder'));  //本地汇订单
const Manifest = AsyncComponent(() => import('./Order/Manifest'));  //送货单
const BusinessType = AsyncComponent(() => import('./Business/BusinessType'));  //商家行业配置
const ApplyBusiness = AsyncComponent(() => import('./Business/ApplyBusiness'));  //商家申请列表
const BusinessList = AsyncComponent(() => import('./Business/BusinessList'));  //商家列表
const BusinessYue = AsyncComponent(() => import('./Business/BusinessYue'));  //商家余额明细
const BusinessTix = AsyncComponent(() => import('./Business/BusinessTix'));  //商家体现列表
const WifiPrint = AsyncComponent(() => import('./Business/WifiPrint'));  //商家行业配置
const MerchantCoinLog = AsyncComponent(() => import('./Business/MerchantCoinLog'));  //商家积分明细
const SupplierList = AsyncComponent(() => import('./Supplier/SupplierList'));  //供应商列表
const SupplierBill = AsyncComponent(() => import('./Supplier/SupplierBill'));  //供应商账单
const GoodsType = AsyncComponent(() => import('./Goods/GoodsType'));  //商品分类
const GoodsList = AsyncComponent(() => import('./Goods/GoodsList'));  //采购商品
const SelfGoodsList = AsyncComponent(() => import('./Goods/SelfGoodsList'));  //自营商品
const RiderGoodsList = AsyncComponent(() => import('./Goods/RiderGoodsList'));  //外卖商品
const Shopper = AsyncComponent(() => import('./Shopper/Shopper'));  //配送员列表
const DeliveryRecord = AsyncComponent(() => import('./Shopper/DeliveryRecord'));  //配送记录
const Finance = AsyncComponent(() => import('./Finance'));  //财务管理
const Comment = AsyncComponent(() => import('./Comment'));  //评论列表
const NewUserList = AsyncComponent(() => import('./NewUser/NewUserList'));  //拉新榜单
const SignList = AsyncComponent(() => import('./Sign/SignList'));  //签到历史
const SignRewardList = AsyncComponent(() => import('./Sign/SignRewardList'));  //奖励历史
const SignSet = AsyncComponent(() => import('./Sign/SignSet'));  //签到配置
const XqList = AsyncComponent(() => import('./xq/XqList'));  //小区列表
const CouponList = AsyncComponent(() => import('./Coupon/CouponList'));  //优惠券列表
const TeamConfig = AsyncComponent(() => import('./User/TeamConfig'));  //团队等级列表
const UserTeamList = AsyncComponent(() => import('./User/UserTeamList'));  //用户团队查看
const OrderYjInfo = AsyncComponent(() => import('./User/OrderYjInfo'));  //订单分佣明细
const UserYjLog = AsyncComponent(() => import('./User/UserYjLog'));  //用户佣金明细
const YjWithdraw = AsyncComponent(() => import('./User/YjWithdraw'));  //佣金提现
const SecKill = AsyncComponent(() => import('./Goods/SecKill'));  //秒杀设置
const MapRange = AsyncComponent(() => import('./Datas/MapRange'));  //配送区域



const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const { TabPane } = Tabs;
const Components = {
    'Rolelist': RoleList,
    "Adminlist": AdminList,
    "Operationlog": OperationLog,
    "Menuset": MenuSet,
    "Uploadset": UploadSet,
    "Basicinfo": BasicInfo,
    "Bannerlist": BannerList,
    "Userreport": UserReport,
    "Revenuereport": RevenueReport,
    "Selflist": SelfList,
    "Offlinebusiness": OfflineBusiness,
    "Riderbusiness": RiderBusiness,
    "Userlist": UserList,
    "Userintegral": UserIntegral,
    "Selforder": SelfOrder,
    "Purchorder": PurchOrder,
    "Riderorder": RiderOrder,
    "Businesstype": BusinessType,
    "Applybusiness": ApplyBusiness,
    "Businesslist": BusinessList,
    "Businessyue": BusinessYue,
    "Businesstix": BusinessTix,
    "Supplierlist": SupplierList,
    "Supplierbill": SupplierBill,
    "Goodstype": GoodsType,
    "Goodslist": GoodsList,
    "Shopper": Shopper,
    "Deliveryrecord": DeliveryRecord,
    "Finance": Finance,
    "Comment": Comment,
    "Basicinfoadmin": BasicInfoAdmin,
    "Selfgoodslist": SelfGoodsList,
    "Ridergoodslist": RiderGoodsList,
    "Setfee": SetFee,
    "LocalOrder": LocalOrder,
    "NewUserList": NewUserList,
    "SignList": SignList,
    "SignRewardList": SignRewardList,
    "CouponList": CouponList,
    "BankSet": BankSet,
    "SignSet": SignSet,
    "WifiPrint": WifiPrint,
    "XqList": XqList,
    "MerchantCoinLog": MerchantCoinLog,
    "TeamConfig": TeamConfig,
    "UserTeamList": UserTeamList,
    "OrderYjInfo": OrderYjInfo,
    "UserYjLog": UserYjLog,
    "YjWithdraw": YjWithdraw,
    "SecKill": SecKill,
    "MapRange": MapRange,
}

export default class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            collapsed: false,
            menu: [],
            headerIcon: 'icon-jibenguanli',
            headerFirstName: '基本管理',
            headerSecName: '角色列表',
            visible: false,
            pwdVisible: false,
            infoVisible: false,
            systemName: "鸿鹄科技管理后台",
            username: "",
            avatar: "",
            selectKey: '',
            openKey: '',
            reqFinished: false,
            tabs: [],
            order_id: ''
        }
        this.tabRef = [];
    }
    componentDidMount() {
        this.getUserInfo();
        setTimeout(() => {
            window.delDom()
        }, 2000);
    }
    toggle = () => {
        var zancOpenKey = '', openKey = '';
        if (!this.state.collapsed) {
            zancOpenKey = this.state.openKey;
            openKey = ''
        } else {
            openKey = this.state.zancOpenKey;
            zancOpenKey = ''
        }
        this.setState({
            collapsed: !this.state.collapsed,
            openKey,
            zancOpenKey,
        });
    };
    changeVisible(key) {
        this.setState({
            [key]: true,
        })
    }
    getUserInfo() {
        global.Ajax.hhtc_request("admin/getLoginInfo", {}).then(data => {
            if (data.code === 1) {
                document.title = data.data.name;
                let menu = data.data.menus;
                // menu[0].child=[
                //     {
                //         id: 999,
                //         path: 'MapRange',
                //         icon: '',
                //         title: '区域配置'
                //     },
                //     ...menu[0].child,
                // ]
                var tabs = [], activeKey = '', selectKey, openKey;
                if (menu.length > 0) {
                    var path;
                    if (menu[0].child.length > 0) {
                        path = (menu[0].child[0].path.split('/'))[menu[0].child[0].path.split('/').length - 1];
                        tabs = [{
                            title: menu[0].child[0].title,
                            component: path.substr(0, 1).toUpperCase() + path.substr(1),
                            key: 'tab' + menu[0].child[0].id,
                            subKey: 'sub' + menu[0].id,
                            menuKey: menu[0].child[0].id
                        }]
                    } else {
                        path = (menu[0].path.split('/'))[menu[0].path.split('/').length - 1];
                        tabs = [{
                            title: menu[0].title,
                            component: path.substr(0, 1).toUpperCase() + path.substr(1),
                            key: 'tab' + menu[0].id,
                            subKey: 'sub' + menu[0].id,
                            menuKey: menu[0].id,
                        }]
                    }
                    activeKey = tabs[0].key;
                    selectKey = tabs[0].menuKey;
                    openKey = tabs[0].subKey;
                    // 判断是否有自营订单和采购订单权限
                    let rows = menu.find(item => item.title == '订单管理');
                    if (rows) {
                        let mlist = rows.child;
                        let selfIndex = mlist.findIndex(item => item.title == '自营订单'),
                            purchIndex = mlist.findIndex(item => item.title == '采购订单');
                        if (selfIndex > -1 || purchIndex > -1) {
                            // console.log('有权限',selfIndex,purchIndex);
                            // 订阅//订阅 'message' 发布的发布的消息
                            this.closeSocket()
                            this.messageSocket = PubSub.subscribe('message', (topic, message) => {
                                //message 为接收到的消息  这里进行业务处理
                                console.log(message)
                                if (message.code == 2 && message.order_id > 0) {
                                    this.setState({
                                        order_id: message.order_id,
                                    }, () => {
                                        if (localStorage.getItem('selfOrderAutoPrint') == 'true' && message.order_type == 1) {
                                            this.printRef.onPrint(message.order_id)
                                        }
                                        if (localStorage.getItem('purchaseOrderAutoPrint') == 'true' && message.order_type == 2) {
                                            this.printRef.onPrint(message.order_id)
                                        }
                                    })
                                }
                            })
                            //连接websocket
                            // let url = `ws://192.168.1.168:2828?token=${localStorage.getItem('honghuToken')}&client=admin`;
                            let url = `wss://tongcheng.honghukeji.net/ws?token=${localStorage.getItem('honghuToken')}&client=admin`;
                            createWebSocket(url)
                        }
                    }
                }
                this.setState({
                    systemName: data.data.name,
                    username: data.data.username,
                    avatar: data.data.avatar,
                    menu,
                    tabs,
                    activeKey,
                    selectKey,
                    openKey,
                    reqFinished: true,
                })
            }
        })
    }
    componentWillUnmount() {
        // 取消订阅
        PubSub.unsubscribe(this.messageSocket);
        // 关闭socket
        closeWebSocket()
    }
    closeSocket() {
        // 取消订阅
        PubSub.unsubscribe(this.messageSocket);
        // 关闭socket
        closeWebSocket()
    }
    handMenu(e) {
        let openKey = this.state.openKey,
            zancOpenKey = this.state.openKey;
        if (this.state.collapsed || e.keyPath.length == 1) {
            openKey = '';
        }
        this.setState({
            selectKey: e.key,
            zancOpenKey,
            openKey,
        })
    }
    handTitle(e) {
        let openKey = ''
        if (this.state.openKey != e.key) {
            openKey = e.key;
        }
        this.setState({
            openKey
        })
    }
    // 刷新
    onRefresh = () => {
        this.tabRef[this.state.selectKey]&&this.tabRef[this.state.selectKey].refresh()
    }
    render() {
        const { collapsed } = this.state;
        const menu = (
            <Menu>
                <Menu.Item key="1" onClick={this.changeVisible.bind(this, 'pwdVisible')}>修改密码</Menu.Item>
                <Menu.Item key="2" onClick={this.changeVisible.bind(this, 'infoVisible')}>个人信息</Menu.Item>
                <Menu.Item key="3" onClick={() => {
                    localStorage.removeItem('honghuToken')
                    PubSub.unsubscribe(this.messageSocket);
                    closeWebSocket()
                    message.success("再见", 1, () => {
                        window.location.href = ''
                        // window.reset()
                    })
                }}>退出登录</Menu.Item>
            </Menu>
        )
        const list = (path, id) => {
            var MyComponentt = Components[path];
            return <MyComponentt _ref={dom => this.tabRef[id] = dom} />;
        }
        return (
            <Layout style={{ minHeight: '100vh', _height: '100vh' }}>
                <Sider trigger={null} theme='light' width={240} collapsible collapsed={collapsed} onCollapse={this.toggle} className='leftMenu'>
                    <div className="logo">
                        <p>{this.state.systemName && this.state.systemName.substr(0, 2)}</p>
                        <h1>{this.state.systemName}</h1>
                    </div>
                    {this.state.reqFinished && <Menu openKeys={[this.state.openKey]} selectedKeys={[String(this.state.selectKey)]} mode="inline" onClick={this.handMenu.bind(this)}>
                        {this.state.menu.map((item, index) => (
                            <React.Fragment key={'' + index}>
                                {(item.path == '' && item.child.length > 0) ?
                                    <SubMenu key={`sub${item.id}`} icon={<p className={`iconfont ${item.icon}`}></p>} title={item.title} onTitleClick={this.handTitle.bind(this)}>
                                        {item.child.map((row, k) => (
                                            <Menu.Item key={String(row.id)} onClick={this.add.bind(this, row, item)}>{row.title}</Menu.Item>
                                        ))}
                                    </SubMenu>
                                    :
                                    <Menu.Item className='firr' key={String(item.id)} icon={<p className={`iconfont ${item.icon}`}></p>} onClick={this.add.bind(this, item, item)}>{item.title}</Menu.Item>
                                }
                            </React.Fragment>
                        ))}
                    </Menu>}
                </Sider>
                <Layout className="site-layout">
                    <Header>
                        <div className='topnav'>
                            <p className={`iconfont ${this.state.collapsed ? 'icon-zhankai' : 'icon-shouqi'} leftMenuToggle`} onClick={this.toggle.bind(this)}></p>
                            <p className={`iconfont icon-shuaxin leftMenuToggle`} onClick={this.onRefresh}></p>
                            <p style={{ marginLeft: 'auto' }} onClick={() => {
                                this.setState({
                                    printVisible: true,
                                })
                            }}>
                                <img alt='' name='打印设置' src={require('../imgs/print.png').default} style={{ width: 32, cursor: 'pointer' }} />
                            </p>
                            <div className='zhut flexCenter' onClick={this.changeVisible.bind(this, 'visible')} style={{ marginLeft: 20 }}>
                                <p className='iconfont icon-zhuti'></p>
                                <p>主题</p>
                            </div>
                            {(this.state.avatar == "" || !this.state.avatar) ?
                                <img alt='' src={require('../imgs/default.png').default} className='avatar' />
                                :
                                <img alt='' src={this.state.avatar} className='avatar' />
                            }
                            <Dropdown placement='bottomCenter' overlay={menu}>
                                <div className='flexCenter'>
                                    <p>{this.state.username}</p>
                                    <span className='iconfont icon-jiantou-shang'></span>
                                </div>
                            </Dropdown>
                        </div>
                    </Header>
                    <Content>
                        {this.state.reqFinished && <Tabs
                            type="editable-card"
                            hideAdd={true}
                            activeKey={this.state.activeKey || 'tab0'}
                            className='asdTabs'
                            onChange={this.onChange}
                            onEdit={this.onEdit}
                        >
                            {this.state.tabs.map(item => (
                                <TabPane closable={this.state.tabs.length == 1 ? false : true} closeIcon={(<p className='iconfont icon-guanbi closeTab'></p>)} tab={item.title} key={item.key}>
                                    {list(item.component, item.menuKey)}
                                </TabPane>
                            ))}
                        </Tabs>}
                        {/* <Router>
                            <Switch>
                                <Route path="/rout/rolelist" component={RoleList} />
                                <Route path="/rout/adminlist" component={AdminList} />
                                <Route path="/rout/operationlog" component={OperationLog} />
                                <Route path="/rout/menuset" component={MenuSet} />
                                <Route path="/rout/uploadset" component={UploadSet} />
                                <Route path="/rout/basicinfo" component={BasicInfo} />
                            </Switch>
                        </Router> */}
                    </Content>
                </Layout>
                {/* 修改主题 */}
                <CustomModal
                    visible={this.state.visible}
                    width={1172}
                    title='主题配色'
                    onCancel={this.onCancel.bind(this)}
                >
                    <SetColor ref={dom => this.setcolor = dom} onCancel={this.onCancel.bind(this)} onOk={this.onOk.bind(this)} />
                </CustomModal>
                {/* 修改密码 */}
                <CustomModal
                    visible={this.state.pwdVisible}
                    width={360}
                    title='修改密码'
                    onCancel={this.onCancel.bind(this)}
                >
                    <EditPwd ref={dom => this.editpwd = dom} onCancel={this.onCancel.bind(this)} />
                </CustomModal>
                {/* 个人信息 */}
                <CustomModal
                    visible={this.state.infoVisible}
                    width={360}
                    title='个人信息'
                    onCancel={this.onCancel.bind(this)}
                >
                    <UserInfo
                        ref={dom => this.userinfo = dom}
                        avatar={this.state.avatar}
                        username={this.state.username}
                        onCancel={this.onCancel.bind(this)}
                        onRefresh={this.getUserInfo.bind(this)}
                    />
                </CustomModal>
                <CustomModal
                    visible={this.state.printVisible}
                    title='打印设置'
                    onCancel={this.onCancel.bind(this)}
                    width={560}
                >
                    <Form
                        onFinish={(req) => {
                            localStorage.setItem('selfOrderAutoPrint', String(req.selfOrderAutoPrint));
                            localStorage.setItem('purchaseOrderAutoPrint', String(req.purchaseOrderAutoPrint));
                            this.onCancel()
                        }}
                        initialValues={{
                            selfOrderAutoPrint: localStorage.getItem('selfOrderAutoPrint') == 'true' ? true : false,
                            purchaseOrderAutoPrint: localStorage.getItem('purchaseOrderAutoPrint') == 'true' ? true : false,
                        }}
                    >
                        <Form.Item label='是否开启自营订单自动打印' name='selfOrderAutoPrint' valuePropName='checked'>
                            <AntSwitch checkedChildren='开启' unCheckedChildren='关闭' />
                        </Form.Item>
                        <Form.Item label='是否开启采购订单自动打印' name='purchaseOrderAutoPrint' valuePropName='checked'>
                            <AntSwitch checkedChildren='开启' unCheckedChildren='关闭' />
                        </Form.Item>
                        <Button type='primary' htmlType='submit' style={{ display: 'block', marginLeft: 'auto' }}>确定</Button>
                    </Form>
                </CustomModal>
                <div id='printBox' style={{ width: 0, height: 0, opacity: 0, overflow: 'hidden' }}>
                    <Manifest ref={dom => this.printRef = dom} resetId={() => {
                        this.setState({
                            order_id: ''
                        })
                    }} />
                </div>
            </Layout>
        )
    }
    add(row, itemm) {
        // console.log(row)
        var { tabs } = this.state;
        var rowItem = tabs.find(item => item.title == row.title);
        if (!rowItem) {
            var path = (row.path.split('/'))[row.path.split('/').length - 1];
            var obj = {
                title: row.title,
                component: path.substr(0, 1).toUpperCase() + path.substr(1),
                key: 'tab' + row.id,
                subKey: 'sub' + itemm.id,
                menuKey: row.id,
            }
            tabs.push(obj);
            this.setState({
                tabs,
                activeKey: obj.key
            })
        } else {
            this.setState({
                activeKey: rowItem.key
            })
        }
        if (row.path != '') {
            // this.props.history.push(row.path)
            this.setState({
                headerIcon: itemm.icon,
                headerFirstName: itemm.title,
                headerSecName: row.title
            })
        }
    }
    onEdit = (targetKey, action) => {
        // console.log(action, targetKey)
        this[action](targetKey)
    }
    remove = targetKey => {
        const { tabs, activeKey } = this.state;
        let newActiveKey = activeKey, selectKey = '', openKey = '';
        let lastIndex;
        tabs.forEach((pane, i) => {
            if (pane.key === targetKey) {
                lastIndex = i - 1;
            }
        });
        const newPanes = tabs.filter(pane => pane.key !== targetKey);
        if (newPanes.length && newActiveKey === targetKey) {
            if (lastIndex >= 0) {
                newActiveKey = newPanes[lastIndex].key;
                selectKey = newPanes[lastIndex].menuKey;
                openKey = newPanes[lastIndex].subKey;
            } else {
                newActiveKey = newPanes[0].key;
            }
        } else {
            newActiveKey = newPanes[newPanes.length - 1].key;
            selectKey = newPanes[newPanes.length - 1].menuKey;
            openKey = newPanes[newPanes.length - 1].subKey;
        }
        let zancOpenKey = openKey;
        if (!this.state.collapsed) {
            // openKey=openKey;
        } else {
            openKey = '';
        }
        this.setState({
            tabs: newPanes,
            activeKey: newActiveKey,
            openKey,
            selectKey,
            zancOpenKey
        });
    }
    onChange = (activeKey) => {
        const { tabs } = this.state;
        var row = tabs.find(item => item.key == activeKey);
        let openKey = '',
            zancOpenKey = row.subKey;
        if (!this.state.collapsed) {
            openKey = row.subKey;
        }
        this.setState({
            activeKey,
            selectKey: row.menuKey,
            openKey,
            zancOpenKey
        })
    }
    onOk() {
        this.onCancel()
    }
    onCancel() {
        this.setState({
            visible: false,
            pwdVisible: false,
            infoVisible: false,
            printVisible: false,
        })
    }
}
